import React from 'react';
import { Link } from 'react-router-dom';
import './assets/css/index.css';
import mainMenu from './mainMenu.js';
import gear from './assets/settings.png';
import contactForm from './assets/contactForm.js';

function Home() {
  let isDark = true;
  let tlmlogo='';
  if (isDark) {
    tlmlogo = "https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png"
  } else {
    tlmlogo = "https://res.cloudinary.com/dzfqshhzu/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1729158275/theleadmanlogo_light-removebg-preview_xxs6de.png"
  };

  return (
  <>
    {mainMenu()}
    <div className='spacer50 dHide' /> 
    {/*/////////////////////////////////////HERO///////////////////////////////////////////////////////////*/}
    <section className="spacer20">
      <div className="hero">
        <div className="heroLeft">
          <h1>Get More Results from Your Website.<span style={{color:'#8093f1'}}>.</span></h1>   
          <p>More traffic, more engagements, more leads, more conversions.</p>
          <div className="spacer30">
            <Link to="/contact"><button className="contactButton heroButton">I want results now &nbsp;&nbsp;
              <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                <path strokeWidth="2" fill="none" className="buttonArrow heroButton" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
              </svg></button>
            </Link>
          </div> 
        </div>
        <div className="heroRight">
          <svg height="400" width="400" xmlns="http://www.w3.org/2000/svg">
            <polyline  className="chart" fill="none" stroke-miterlimit="10" strokeWidth="15"   points="10,400 100,250 250,300 360,50" />
            <polygon points="340,45 376,60 385,0" className="polygon" opacity="0"> 
              <animate attributeType="CSS" attributeName="opacity" from="0" to="1" begin="1.3s" dur="0.35s" fill="freeze"/>
            </polygon>
          </svg>
        </div>
      </div>
    </section>
            
    {/*///////////////////////////////////////BEYOND DESIGN/////////////////////////////////////////////////////////*/}

    <section>
      <div className="afterHero">
        <div className="columns reverse mc">
          <div className="columns50">

          </div>
        <div className="">
          <div className="spacer50 mHide"></div>
          <div className="spacer30 dHide"></div>   
              <div className="columns mcolumns slider fcenter grid100">
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" alt="client logo"/>
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" alt="client logo" />
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" alt="client logo" />
                  <img prefetch src={tlmlogo} alt="client logo" />
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" alt="client logo" />
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" alt="client logo" />
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" alt="client logo" />
                  <img prefetch src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" alt="client logo" />
                  {/*repeat for loop*/}
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" alt="client logo" />
                  <img prefetch className="" src={tlmlogo} alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" alt="client logo" />
                  <img prefetch className="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" alt="client logo" />
                    {/*repeat for loop*/}
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" alt="client logo" />
                  <img prefetch className="mHide" src={tlmlogo} alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" alt="client logo" />
                  {/*repeat for loop*/}
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720591164/benfik-removebg-preview_kmwd9q.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1718744619/Screenshot_2023-10-10_at_04.57.55-removebg-preview_qticfa.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1705443462/vivaLuxe-logo.webp" alt="client logo" />
                  <img prefetch className="mHide" src={tlmlogo} alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594535/finalbionic-01_vrydus.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720594374/Image-PNG-Transparent-Exact-Large_xyiqfd.png" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592917/Lasht-n-2900-x-850-px-4_i19kcd.svg" alt="client logo" />
                  <img prefetch className="mHide" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1720592643/Raven-Gold_logo-removebg-preview-1-e1664598593566_mj6gvx.png" alt="client logo" />
              </div> 
        
        </div>
      </div>
    </div></section>
            <div className="spacer50"></div>

            
            {/*////////////////////////////////HARNESS TECH////////////////////////////////////////////////////////////////*/}
            <div className="parallax bg1"></div>
             <section><div className="pad border lightBulb">
                <div className="spacer50"></div>
                  <h1 className="boldHeader slideIn">Struggling with low traffic and conversions? Set up <img className="spin gear" src={gear}  alt=" " /> your website to grow your business.</h1>
                         <div className="columns top">
                             <div className="columns50 mHide">
                                <div className="spacer50"></div>
                                
                              </div>
                            
                                <div className="columns50 mc">
                                  
                                <div className="spacer50"></div>
                           
                            <p>
                              Beyond design, we build websites that <i style={{backgroundColor:'#9fddfe', padding:'0 5px', color:'#1f1f1f', marginRight:'3px'}}>understand</i> 
                              the psychology of your customers and speak uniquely with them.
                              This helps you <i style={{backgroundColor:'', padding:'0 5px 0 0', color:'', marginRight:'3px'}}>connect</i> 
                              with your target audience, drive conversions and build relationships.
                            </p>
                            <div className="spacer20"></div>
                            <Link to="/contact"><button className="contactButton">I want results now &nbsp;&nbsp;
                                  <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                                     <path strokeWidth="2" fill="none" className="buttonArrow" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                                  </svg></button>
                            </Link>
                          <div className="spacer50"></div>
                        </div>
                   </div>
                </div>
              </section>
            
        {/*////////////////////////////////AFTER PARTNERING////////////////////////////////////////////////////////////////*/}
             <section><div className="spacer100 "></div>
                  <div className="counters"><div className="spacer50"></div>
                   <h3 className="bigHeading center">After partnering with us, our clients typically experience...</h3>
                   <div className="spacer30"></div>
                   <div className="columns mHide grid grid50 ">
                       <div className="columns25 center counterWrapper gridBox">
                          <p> <span className="counter"><span className="purple counterIcon">+</span> 60% </span><br/>increase in traffic</p>
                       </div>
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 83% </span><br/>more engagement</p>
                       </div>
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 70% </span><br/>more leads</p>
                       </div>
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 50%</span><br/>more conversions</p>
                       </div></div>
                       
                       {/*Mobile counters*/}
                        <div className="mcolumns dHide grid50">
                       <div className="columns25 center counterWrapper gridBox">
                          <p> <span className="counter"><span className="purple counterIcon">+</span> 60% </span><br/>increase in traffic</p>
                       </div>
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 83% </span><br/>more engagement</p>
                       </div></div>
                       <div className="mcolumns dHide grid50">
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 79% </span><br/>more leads</p>
                       </div>
                       <div className="columns25 center counterWrapper gridBox">
                           <p><span className="counter"><span className="purple counterIcon">+</span> 50%</span><br/>more conversions</p>
                       </div></div>
           
           <div id="services" className="spacer50"></div><div className="spacer20"></div></div>
           </section>
    
            {/*////////////////////////////////HOW WE GET RESULTS////////////////////////////////////////////////////////////////*/}
            <section><div className="spacer100"></div><div className="spacer100"></div>
                  <div className="mspacer">
                   <h3 className="bigHeading center">Here is how we will get you results</h3>
                   <div className="spacer30"></div>
                   <div className="columns grid20">
                       <div className="columns3 center">
                           <div className="gridBox blueBg">
                             <h6 className="smHeading">Websites engineered for more conversions<br/> </h6>
                             <div className="spacer20"></div>
                               
                                <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271259/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_fbl3hg.jpg" alt="website development and optimisation - BITe Agency.pro" />
                           </div>
                       </div>
                       <div className="columns3 center">
                           <div className="gridBox purpleBg">
                             <h6 className="smHeading">Content that connects with your customers</h6>
                             <div className="spacer20"></div>
                              <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271258/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_1_puxyjg.jpg" alt="website content that drives conversions - BITe Agency.pro" />
                           </div>
                       </div>
                       <div className="columns3 center">
                           <div className="gridBox lavBg">
                             <h6 className="smHeading">Creative advertising to reach the right people</h6>
                             <div className="spacer20"></div>
                               <img className="resultsImg" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1724271259/Black_White_Vintage_Retro_Dynamic_Digital_Marketing_Presentation_3_mfvh07.jpg" alt="Reach the right audience with creative advertising - BITe Agency.pro" />
                           </div>
                       </div>
                </div><div className="spacer20"></div>
                    <div className="gridBox blueBg ' ' + columns">
                              <div className="columns20">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <style>
      {`
        .envelope { fill: #8093f1; }
        .flap { fill: #f4f4ed; }
        .background { fill: #9fddfe; }
      `}
    </style>
    <rect x="10" y="30" width="80" height="40" className="envelope" />
    <polygon points="10,30 50,60 90,30" className="flap">
      <animate id="crm" attributeName="points" values="10,30 50,60 90,30; 10,50 50,30 90,50; 10,30 50,60 90,30" dur="5s" begin="4s;crm.end+5s" />
    </polygon>
    <rect x="10" y="10" width="80" height="20" className="background" />
    </svg>
                              </div>
                              <div className="columns80 mcenter gridBox  mnopad">
                                 <h6 className="smHeading">Customer relationship management (CRM)</h6>
                                 <div className="spacer20"></div>
                                  <p className="footerP">We help you stay top of mind of your customers to keep them coming back.<br/>
                                  It's only half the results if you don't build a relationship that brings them back.
                                  </p>
                             </div>                               
                    </div>
                    
                       <div className="spacer20"></div>
                       <div className="center">
                       <Link to="/contact"><button className="contactButton">I want results now &nbsp;&nbsp;
                         <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                             <path strokeWidth="2" fill="none" className="buttonArrow" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                         </svg></button>
                    </Link>
                <div className="spacer50"></div>
              </div>
        </div>
            </section><div className="spacer100"></div>
         {/*////////////////////////////////BRANDS////////////////////////////////////////////////////////////////*/}
            {/*<section ><div className="spacer50"></div>
                  <div className="borderTop center"><div className="spacer50 mSpacer"></div>
                      <h3 className="bigHeading">We've done it for brands like yours, let's do it for you too.</h3>
                      
                      <div  className="spacer50"></div>
                 </div>  
            </section><div className="spacer50"></div>*/}
     {/*////////////////////////////////CONTACT////////////////////////////////////////////////////////////////*/}
         {/*<div className="parallax bg2"></div>*/}
            <section id="contact"><div className="spacer100 borderTop"></div>
                  <div className="">
                      
                      <div className="spacer30"></div>   
                      <div className="columns grid fcenter reverse top grid50">
                          <div className="columns70">
                          <div className="mspacer"></div>
                          <div className=""></div>
                              {contactForm()}
                          </div>
                          <div className="columns30 sticky">
                              <h3 className="bigHeading">Set up your website for big results.</h3>
                              <div className="spacer20"></div>
                              <p>
                                   Whatever you are looking to achieve, whether it is to get more traffic, reduce bounce rates
                                   or get more people to schedule a consultation with you, we
                                    <i style={{backgroundColor:'#9fddfe', padding:'0 5px', color:'#1f1f1f', marginRight:'3px'}}>guarantee</i> 
                                   we can improve your present outcomes by at least 30% within a period of 60 days. 
                                   If we don’t, we’ll work for you till we do.
                              </p>
                              
                          </div>
                      </div> 
                      <div className="spacer100"></div>
                 </div>  
            </section>
            {/*/////////////////FOOTER///////////////////*/}     
  </>  
  )     
}

export default Home;