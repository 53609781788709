import React from 'react';
import { Link } from 'react-router-dom';
import './assets/css/services.css';
import { MdOutlineLanguage, MdOutlinePhonelink, MdOutlineWeb, MdOutlineStore, MdOutlineBusiness, MdOutlineComputer } from 'react-icons/md';
import { FaBriefcase } from 'react-icons/fa';

function Services() {

    return (
        <>
            {/*HERO                HERO                   HERO                       HERO             HERO */}
            <div className="noMenu">
                <div className="pg-hero centralHero flcenter row center gradientBg">
                    
                    <div>
                        <div className="bigHeading" > <MdOutlinePhonelink /> <MdOutlineWeb /> <MdOutlineLanguage /></div>
                        <h1 className="f70 mf30 wt400">Websites that take you to the world. </h1>
                        <p>Everything you need to run a successful website rolled up in one partner.</p>
                    </div>
                    <div className="row grid10 spacer0">
                        <div className="spacer50 grid10 columns">
                            <div className="card w50 left blueBg">
                                <h3 className="smHeading">Website Development</h3>
                                <p className="footerSmP spacer20"> Clean professional deisgn, loads fast on all devices. </p>

                            </div>
                            <div className="card left w50 blueBg">
                                <h3 className="smHeading">Website Content</h3>
                                <p className="footerSmP spacer20">Speak to your customer's painpoint and increase sign ups.</p>
                                
                            </div>
                        </div>
                        <div className="columns grid10 ">
                            <div className="card left w50 blueBg">
                                <h3 className="smHeading">Customer Relations</h3>
                                <p className="footerSmP spacer20">Personalized follow up emails that foster repeat orders. </p>

                            </div>
                            <div className="card left w50 blueBg">
                                <h3 className="smHeading">Coming Soon...</h3>
                                <p className="footerSmP spacer20">Mobile app development and growth marketing.</p>
                                
                            </div>
                        </div>
                    </div> 
                </div>
            </div>


            <div className="columns mp20 grid100 start">
                <div className="columns40 padLeft50 spacer100">
                    <h3 className='f50 spacer100'>Website development</h3>
                    <p className="spacer20">
                        Your website is the hub of your online presence. You own it, you control it.
                        You don't get barred and loose access to your customers for what you say or do.
                        <br/> <br/>
                        This stability is perhaps the biggest reason you should invest in a website.
                        We develop the following website types:
                    </p>
                    <div className="spacer50 row w100 grid10">
                        <div className="card blueBg">
                            <div className="columns mcolumns spaceBetween">
                                <h4 className="f30 mf25">eCommerce website</h4>
                                <MdOutlineStore className="f30" />
                            </div>
                            <p className='f15'>
                                Sell and deliver your products to anyone, anywhere.
                            </p>
                        </div>

                        <div className="card blueBg">
                            <div className="columns mcolumns spaceBetween">
                                <h4 className="f30 mf25">Business website</h4>
                                <MdOutlineBusiness className="f30" />
                            </div>
                            <p className='f15'>
                                Schedule consultations and send follow ups seamlessly.
                            </p>
                        </div>

                        <div className="card blueBg">
                            <div className="columns mcolumns spaceBetween">
                                <h4 className="f30 mf25">Portfolio</h4>
                                <FaBriefcase className="f25" />
                            </div>
                            <p className='f15'>
                                Showcase your best work beautifully.
                            </p>
                        </div>

                        <div className="card blueBg">
                            <div className="columns mcolumns spaceBetween">
                                <h4 className="f30 mf25">SaaS websites</h4>
                                <MdOutlineComputer className="f30" />
                            </div>
                            <p className='f15'>
                                Sell your softwares with detailed documentation.
                            </p>
                        </div>

                        <div className='lg:py-24 spacer100' />  
                        
                        <h4 className='f30'>Technologies we use</h4>
                        <div className='flex vcenter mgrid grid20'>
                            <img className='techLogo' alt="wordpress" height="" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/wp-logo-removebg-preview_pbjpyh.png" />
                            <img className='techLogo' alt="wix" height="" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/wix-logo-removebg-preview_m5uvvn.png" />
                            <img className='techLog' alt="node.js" height="120px" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/node-logo-removebg-preview_zss2fj.png" />
                        </div>

                        <div className='flex vcenter grid20'>
                            <img className='techLogo' alt="next.js" height="80px" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/next-logo-removebg-preview_yktnse.png" />
                            <img className='techLogo' alt="react.js" height="60px" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/react_logo-removebg-preview_pbpppw.png" />
                            <img className='techLogo' alt="tailwind css" height="60px" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/tailwind-logo-removebg-preview_qsgvrx.png" />
                            <img className='techLogo' alt="material ui" height="80px" width="" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1737908386/mui-logo-removebg-preview_zgfwzh.png" />

                        </div>

                        <div className=' spacer50' />
                        <Link to="/contact"><button className="contactButton heroButton">Start my project &nbsp;&nbsp;
                            <svg height="10" width="70" xmlns="http://www.w3.org/2000/svg">
                            <path strokeWidth="2" fill="none" className="buttonArrow heroButton" d="M0,5 L60,5 L60,0 L70,5 L60,10 L60,5"/>
                            </svg></button>
                        </Link>

                        <div className=' spacer100' />
                        <div className=' spacer100' />

                    </div>
                </div>
                <div className="columns60 h100 r20 purpleBg sticky10 mHide"></div>
            </div>

        </>
    );
}

export default Services;