import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
import { useLocation, BrowserRouter as Router, Routes, Route, Outlet, NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import Loader from './loader.js';
import './assets/css/bite-style.css';
import { MdOutlineHome, MdOutlineDisplaySettings, MdOutlineForum, MdOutlineArrowDownward, MdOutlineArrowDropDown} from "react-icons/md";
import Toggle from './toggle.js';
import { Divider } from './pages/assets/modules.js';
import Home from './pages/home.js';
import Services from './pages/services.js';
import Contact from './pages/contact.js';
import NoPage from './pages/nopage.js';
import Footer from './footer.js';
const isOsSettingDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

function Content() {
  const [isDark, setIsDark] = useState(false);
  const [checked, setChecked] = React.useState(true);
  let mode='';
  
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      // First jump to 50px from the top (to hide the "scrolling from the bottom" effect)
      window.scrollTo(0, 10);
  
      // Then use requestAnimationFrame to smoothly scroll from 50px to the top
      requestAnimationFrame(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
    }, [pathname]);
  
    return null;
  }

  return (
  <>
    <div  data-theme={isDark ? "dark" : "light"}>
      <Router className="BiteApp">
        <ScrollToTop />
      <div className='appMenu'>
        <div className='appMenuItems'>
          <NavLink exact to="/" activeClassName="active">
            <div className="appIcon">
              < MdOutlineHome />
            </div>
            <div>Home</div>
          </NavLink>

          <NavLink to="/services" activeClassName="active" id="servicebutton" onMouseOver={openSubmenu}>
            <div className="appIcon">
              < MdOutlineDisplaySettings />
            </div>
            <div>Services</div>


             {/*SUB MENU DESKTOP                       SUB MENU DESKTOP                     SUB MENU DESKTOP */}

            <div className=" subMenu mHide" id="subMenu">
              <div className="f20 left">Website Development</div>
              <div className="f17 left spacer20 row grid10 dropdown">
                <div className="columns spaceBetween dropdownbtn" activeClassName="open" onclick="">
                  By functionality 
                  <MdOutlineArrowDropDown className="down f25"/>
                </div>
                <div className="content row grid10">
                  <Link to="">eCommerce</Link>
                  <Link to="">Business websites (B2B & B2C)</Link>
                  <Link to="">Portfolio websites</Link>
                </div>
              </div>
              <div className="left spacer20 row grid10 dropdown">
                <div className="f17 columns spaceBetween dropdownbtn" activeClassName="open" onclick="">
                  By platform
                  <MdOutlineArrowDropDown className="down f25"/>
                </div>
                <div className="content row grid10">
                  <Link to="">Custom websites</Link>
                  <Link to="">WordPress</Link>
                  <Link to="">Webflow</Link>
                  <Link to="">Wix</Link>
                  <Link to="">Shopify</Link>
                </div>
              </div>

              <div className="spacer20"></div>
              <Divider />
              <div className="f20 left">Website Content</div>

            </div>
          </NavLink>

          <NavLink to="/contact" activeClassName="active">
            <div className="appIcon">
              < MdOutlineForum />
            </div>
            <div>Contact</div>
          </NavLink>
        </div>
        <div className="bottomAligned">
          <div className='toggle flcenter'>
            <Toggle isChecked={isDark} handleChange={() => setIsDark(!isDark)} />{isDark? mode="Light mode" :  mode="Dark mode"}
          </div>
        </div>
      </div>
      

      <div className="appScreen" id="home">
        <div className="actual">

          <Routes>
            <Route path="/" element={<Home />} ></Route>
            <Route path="/home" element={<Home />} ></Route>
            <Route path="/services" element={<Services />} ></Route>
            <Route path="/contact" element={<Contact />} ></Route>
            <Route path="*" element={<NoPage />} ></Route>
          </Routes>

          <Outlet />
        
          <Footer />
          
          {/*close actual app screen*/}
        </div>
      </div>
      </Router>
    </div>
  </>
  );
}

let subMenu = document.getElementById('subMenu');
let servB = document.getElementById('servicebutton');

function openSubmenu () {
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {favoritecontent: <Loader/>};
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({favoritecontent: <Content/>})
    }, 1000)
  }
  
  render() {
    return (
      <>{this.state.favoritecontent}</>
    );
  }
}

const preloader = ReactDOM.createRoot(document.getElementById('Bite'));
preloader.render(<App/>);