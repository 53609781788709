import React from 'react';
import { Link } from "react-router-dom";


function NoPage() {

    return (
        <div className="noMenu">
            <div className="pg-hero columns hmHeroBg padLeft50">
                <div className="heroLeft columns70 bigHeading ">
                <h1>You seem a little lost </h1>
                    <div className="columns80 spacer20">
                        <p> That might be an issue from our end. Hop on any of these shuttles, <br/>
                            and we'll take you to your destination in no time.
                        </p>
                        <div className="spacer50 columns grid50">
                            <Link to="/home"><button className="contactButton heroButton columns">Home &nbsp; <span className="sobig"> 🛸</span>
                                </button>
                            </Link>
                            <Link to="/home"><button className="contactButton heroButton columns">Improve my conversions &nbsp; <span className="sobig"> 🛸</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    );
}

export default NoPage;