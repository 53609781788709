import './css/modules.css';


function Divider() {
	return (
		<>
				<div className="divider"></div>
		</>
	)
}

export { Divider }