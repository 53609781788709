import React from 'react';
import './assets/css/index.css';
import mainMenu from './mainMenu.js';
import gear from './assets/settings.png';
import contactForm from './assets/contactForm.js';

function Contact() {

  return (
  <>
    <head>
      <title>Contact Us</title>
    </head>
    {mainMenu()}
    <div className='spacer50 dHide' />

    {/*  */}

    <section className="center mspacer">
      <h3 className="uppercase mf25 mleft"> <br/> Go better, grow bigger 📈</h3>
    </section>

    {/*////////////////////////////////CONTACT////////////////////////////////////////////////////////////////*/}
    <section id="contact"><div className="mHide spacer50"></div>
      <div className="">
          
        <div className="mHide spacer30"></div>   
        <div className="columns start fcenter mspacer reverse mreverse grid mgrid0">
          <div className="columns70">
          <div className="mspacer"></div>
          <div className=""></div>
            {contactForm()}
          </div>
          <div className="columns30 sticky">
              <h3 className="smHeading">Set up your website for big results.</h3>
              <div className="spacer20"></div>
              <p>
                Whatever you are looking to achieve, whether it is to get more traffic, reduce bounce rates
                or get more people to schedule a consultation with you, we
                <i style={{backgroundColor:'#9fddfe', padding:'0 5px', color:'#1f1f1f', marginRight:'3px'}}>guarantee</i> 
                we can improve your present outcomes by at least 30% within a period of 60 days. 
                If we don’t, we’ll work for you till we do.
              </p>
              
          </div>
        </div> 
        <div className="spacer100"></div>
      </div>  
    </section>
    {/*/////////////////FOOTER///////////////////*/}     
</>  
  )     
}

export default Contact;